// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-education-system-js": () => import("./../src/pages/education-system.js" /* webpackChunkName: "component---src-pages-education-system-js" */),
  "component---src-pages-events-js": () => import("./../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-events-study-in-rwanda-ur-orientation-js": () => import("./../src/pages/events/study-in-rwanda-ur-orientation.js" /* webpackChunkName: "component---src-pages-events-study-in-rwanda-ur-orientation-js" */),
  "component---src-pages-events-university-of-rwanda-graduation-2019-js": () => import("./../src/pages/events/university-of-rwanda-graduation-2019.js" /* webpackChunkName: "component---src-pages-events-university-of-rwanda-graduation-2019-js" */),
  "component---src-pages-exchange-programs-js": () => import("./../src/pages/exchange-programs.js" /* webpackChunkName: "component---src-pages-exchange-programs-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-institution-ranking-js": () => import("./../src/pages/institution-ranking.js" /* webpackChunkName: "component---src-pages-institution-ranking-js" */),
  "component---src-pages-invest-in-rwanda-js": () => import("./../src/pages/invest-in-rwanda.js" /* webpackChunkName: "component---src-pages-invest-in-rwanda-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-growth-of-education-system-js": () => import("./../src/pages/news/growth-of-education-system.js" /* webpackChunkName: "component---src-pages-news-growth-of-education-system-js" */),
  "component---src-pages-partnership-js": () => import("./../src/pages/partnership.js" /* webpackChunkName: "component---src-pages-partnership-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

